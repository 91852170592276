export const isBrowser = () => typeof window !== "undefined"

export const getCurrentUser = () =>
  isBrowser() && window.localStorage.getItem("auth_user")
    ? JSON.parse(window.localStorage.getItem("auth_user") || '')
    : {}

export const setUser = (user: any) =>
  window.localStorage.setItem("auth_user", JSON.stringify(user))

export const setUserData = (newUserData: any) => {
  let { access_token, user } = getCurrentUser()
  window.localStorage.setItem("auth_user", JSON.stringify({access_token, user: newUserData}))
}

export const isLoggedIn = () => {
  const { user } = getCurrentUser()

  return !!user?.firstName
}

export const logout = (callback: () => void | any) => {
  setUser({})
  callback()
}