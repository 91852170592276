import React, { createContext, useEffect, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { Theme, ThemeProvider } from '@mui/material';

import MetaLayout from './meta-layout';
import { FCR, themes, getCookie, setCookie, themeCookie } from '../../util';
import Notification from '../AppAlert';
import { isLoggedIn } from '../../services/auth';
import { getCurrentUser, isBrowser, logout } from '../../services/auth';
interface contextProps {
    lang: string
    setLanguage: React.Dispatch<string>
}

export const ContentContext = createContext<contextProps>({
    lang: '',
    setLanguage: () => null
})
const Layout: FCR = ({ children, pageContext }) => {
    const [theme, setTheme] = useState<Theme>();
    const switchTheme = (): void => {
        setTheme((prev) => {
            const newTheme = prev?.palette.mode === 'dark' ? 'light' : 'dark';
            setCookie(themeCookie, newTheme);
            return themes[newTheme];
        });
    };
    const [lang, setLang] = useState<string>('')

    const setLanguage = (lang: string) => {
        localStorage.setItem('language', lang)
        setLang(lang)
    }

    const getLang = (): string | null => {
        const ln_local =
            typeof window !== "undefined" ? localStorage.getItem("language") : null;
        const SYSTEM_LANGUAGE = ln_local || setDefaultLanguage();
        return SYSTEM_LANGUAGE;
    }

    const setDefaultLanguage = (lang?: string | null): string | null => {
        const systemLanguage =
            typeof window !== "undefined" ? navigator.language.split("-")[0] : "null";
        typeof window !== "undefined"
            ? localStorage.setItem("language", lang || systemLanguage)
            : null;
        return systemLanguage;
    };

    useEffect(() => {
        const lang = getLang();
        if (lang) setLanguage(lang);

    }, []);

    useEffect(() => {
        setTheme(themes[getCookie(themeCookie) || 'light'])
    }, [isLoggedIn()])

    const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        function handleClick() {
            resetLogoutTimer();
        }

        function startLogoutTimer() {
            setLogoutTimer(setTimeout(logoutUser, 60 * 1000 * 45)); // 45 min in milliseconds
        }

        function resetLogoutTimer() {
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
            startLogoutTimer();
        }

        function logoutUser() {
            document.removeEventListener('click', handleClick);

            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }

            logout(() => isBrowser() && navigate('/login'));
        }

        document.addEventListener('click', handleClick);
        startLogoutTimer();

        return () => {
            document.removeEventListener('click', handleClick);
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
        };
    }, []);

    return (
        theme &&
        (
            <ContentContext.Provider value={{ lang, setLanguage }}>
                <ThemeProvider theme={theme}>
                    <Notification>
                        {pageContext?.layout !== 'app' ? (
                            <div className='auth-container'>{children}</div>
                        ) : (
                            <MetaLayout switchTheme={switchTheme}>{children}</MetaLayout>
                        )}
                    </Notification>
                </ThemeProvider>
            </ContentContext.Provider>
        ) || <></>
    );
};

export default Layout;
