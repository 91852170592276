import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink, graphql, navigate, useStaticQuery } from 'gatsby';
import { Toolbar, Typography, Link, Theme, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LanguageIcon from '@mui/icons-material/Language';
import Logout from '@mui/icons-material/LogoutSharp';

import { FC } from '../../util';
import Logo from '../../images/logo-bmr.webp';
import { getCurrentUser, isBrowser, logout } from '../../services/auth';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import CustomBackdrop from '../CustomBackdrop';
import axiosInstance from '../../util/axiosConfig';
import { NotificationContext, SEVERITY } from '../AppAlert';
import { ContentContext } from './layout';

const useStyles = makeStyles((theme: Theme) => ({
    logoContainer: {
        width: '120px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    link: {
        textDecoration: 'none !important'
    },
    logo: {
        backgroundColor: "rgba(255,255,255,0.8)",
        padding: "6px",
        marginTop: "5px",
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    sub_actions: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }

}));

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export interface HeaderProps {
    switchTheme: () => void
    siteTitle?: string
    open: boolean
    toggleDrawer: () => void
    switchLanguage: () => void
    lang: string
}

const Header: FC<HeaderProps> = (props) => {
    const [showOn, setShowOn] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { showNotification } = React.useContext(NotificationContext)

    const styles = useStyles()

    const { allStrapiHeader } = useStaticQuery(
        graphql`
            query {
                allStrapiHeader {
                    nodes {
                        localizations {
                            data {
                              attributes {
                                stripe_button
                              }
                            }
                        }
                        stripe_button
                    }
                }
            }
        `
    );

    const { lang: language } = React.useContext(ContentContext)

    const headerNode = language === "fr" ? allStrapiHeader.nodes[0] || {} : allStrapiHeader.nodes[0].localizations.data[0].attributes || {};

    const onSwitch = (): void => {
        setShowOn((prev) => !prev)
        props.switchTheme()
    };

    useEffect(() => {
        const { user } = getCurrentUser()
        setCurrentUser(user)
    }, [])

    const goToStripeDashboard = () => {
        setLoading(true)
        axiosInstance.get('/users/stripe/dashboard')
            .then((res) => {
                if (isBrowser()) {
                    const data = res.data

                    if (data.success) {
                        window.open(data.url, '_blank')?.focus()
                    } else {
                        showNotification(`Vous n'avez pas encore ajouté vos informations de paiement.`, SEVERITY.ERROR)
                    }
                }
            })
            .catch((err) => {
                showNotification(err?.response?.data?.message || 'We encountered an error!', SEVERITY.ERROR)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <AppBar position="absolute" open={props.open}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(props.open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <div className={styles.logoContainer}>
                    {currentUser && (
                        <img className={styles.logo} src={currentUser?.logo} alt='user logo' width={150} />
                    )}
                </div>
                <div className={styles.sub_actions}>
                    {/* <IconButton onClick={onSwitch} color="inherit">
                        {showOn ? <DarkModeIcon /> : <LightModeIcon />}
                    </IconButton> */}
                    {currentUser?.isOnboardingComplete && (
                        <Button variant='outlined' size='small' onClick={goToStripeDashboard} color="inherit" endIcon={<MoneyIcon />}>
                            {headerNode?.stripe_button}
                        </Button>
                    )}
                    <Button onClick={props.switchLanguage} color="inherit" startIcon={<LanguageIcon />}>
                        {props.lang?.toUpperCase()}
                    </Button>
                    <IconButton onClick={() => logout(() => isBrowser() && navigate('/login'))}
                        color="inherit">
                        <Logout />
                    </IconButton>
                    <CustomBackdrop open={loading} setOpen={setLoading} />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
