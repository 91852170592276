import deepMerge from 'deepmerge';
import { createTheme, responsiveFontSizes, ThemeOptions, Theme } from '@mui/material';
import { getCurrentUser, isLoggedIn } from '../services/auth';

const makeTheme = (variant: ThemeOptions): Theme => {
    const common = {
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: '0.5rem'
                    },
                    '*::-webkit-scrollbar-thumb': {
                        background: '#888'
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        background: '#555'
                    }
                }
            }
        },
        typography: {
            button: {
              textTransform: 'none'
            }
          }
    };
    const theme = createTheme(deepMerge(common, variant));
    return responsiveFontSizes(theme);
};

const primaryColor = isLoggedIn() ? (getCurrentUser().user.primaryColor || '#007C42')  : '#007C42'
const secondaryColor = isLoggedIn() ? (getCurrentUser().user.secondaryColor || '#007C42')  : '#7cd2f7'

const light: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        },
        error: {
            main: '#f03e3e'
        },
        warning: {
            main: secondaryColor
        },
        info: {
            main: secondaryColor
        },
        success: {
            main: '#4fe054'
        },
        text: {
            primary: '#343a40',
            secondary: '#2e3133',
            disabled: '#48494a'
        },
        background: {
            default: '#f2f2f2',
            paper: '#fff'
        }
    }
};

const dark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#003e21'
        },
        secondary: {
            main: '#0b1e26'
        },
        error: {
            main: '#870505'
        },
        warning: {
            main: '#964c00'
        },
        info: {
            main: '#09539c'
        },
        success: {
            main: '#034d06'
        },
        text: {
            primary: '#f8f9fa',
            secondary: '#e4e7eb',
            disabled: '#ccc'
        },
        background: {
            default: '#212121',
            paper: '#00130a'
        }
    }
};

const themes: { [key: string]: Theme } = {
    light: makeTheme(light),
    dark: makeTheme(dark),
};

export const themeCookie = 'clthmvar';

export default themes;
