import * as React from "react"
import { Alert, AlertTitle, Snackbar, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Lazy } from '.'

export const CustomAlert = styled(Alert)(({ theme, severity }) => ({
  backgroundColor:
    severity === SEVERITY.ERROR
      ? theme.palette.error.main
      : severity === SEVERITY.WARNING
      ? theme.palette.warning.main
      : severity === SEVERITY.SUCCESS
      ? theme.palette.success.main
      : theme.palette.info.main,
  color: theme.palette.getContrastText(
    severity === SEVERITY.ERROR
      ? theme.palette.error.main
      : severity === SEVERITY.WARNING
      ? theme.palette.warning.main
      : severity === SEVERITY.SUCCESS
      ? theme.palette.success.main
      : theme.palette.info.main
  ),
}))

export const NotificationContext = React.createContext({
  showNotification: (message: string, severity: SEVERITY) => {},
})

export enum SEVERITY {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

export default function Notification(props: any) {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState("")
  const [severity, setSeverity] = React.useState("info")

  const handleOpen = (message: string, severity: SEVERITY) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: string | any) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <NotificationContext.Provider value={{ showNotification: handleOpen }}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} 
                anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <CustomAlert
          severity={severity}
          onClose={handleClose}
          closeText="Close"
        >
          {message}
        </CustomAlert>
      </Snackbar>
      {props.children}
    </NotificationContext.Provider>
  )
}