import * as yup from "yup";

export const addProductYupValidation = yup.object().shape({
    title: yup.string().required("Required !"),
    description: yup.string().required("Required !"),
    amount: yup.string().required("Required !"),
    sku: yup.string().required("Required !"),
    weight: yup.number().min(1).max(6999).required("Required !"),
    state: yup.string().required("Required !"),
    manufacturer: yup.string().required("Required !"),
    image: yup.mixed().required("An image is required !")
            .test(
                "fileSize",
                "File size must be less than 2MB",
                (value) => value && value.size <= 2 * 1024 * 1024
            )
            .test(
                "fileType",
                "File type must be jpeg or png",
                (value) =>
                    value &&
                    (value.type === "image/jpeg" || value.type === "image/png")
            ),
})

export const editProductYupValidation = yup.object().shape({
    title: yup.string().required("Required !"),
    description: yup.string().required("Required !"),
    amount: yup.string().required("Required !"),
    sku: yup.string().required("Required !"),
    weight: yup.number().min(1).max(6999).required("Required !"),
    state: yup.string().required("Required !"),
    manufacturer: yup.string().required("Required !"),
})

export const settingSchema = yup.object().shape({
    firstName: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    telephone: yup.string().matches(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/, "Numéro de téléphone valide requis au format canadien (xxx-xxx-xxxx)"),
    email: yup.string().email('Invalid email').required('Required'),
    logo: yup.mixed()
        .test(
            "fileSize",
            "File size must be less than 2MB",
            (value) => (value && value?.size <= 2 * 1024 * 1024) || true
        )
        .test(
            "fileType",
            "File type must be jpeg or png",
            (value) =>
                (value &&
                (value.type === "image/jpeg" || value.type === "image/png")) || true
        ),
    password: yup.string().min(8, "Password must be at least 8 characters long"),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    company: yup.string().required('Required'),
});

export const signupSchema = yup.object().shape({
    firstName: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    telephone: yup.string().matches(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/, "Numéro de téléphone valide requis au format canadien (xxx-xxx-xxxx)"),
    email: yup.string().email('Invalid email').required('Required'),
    logo: yup.mixed()
        .required("An image is required")
        .test(
            "fileSize",
            "File size must be less than 2MB",
            (value) => value && value.size <= 2 * 1024 * 1024
        )
        .test(
            "fileType",
            "File type must be jpeg or png",
            (value) =>
                value &&
                (value.type === "image/jpeg" || value.type === "image/png")
        ),
    password: yup.string().min(8, "Password must be at least 8 characters long").required("Password is required"),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    company: yup.string().required('Required'),
});

export const signinSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
    password: yup.string().min(2, "Password must be at least 8 characters long").required("Password is required"),
})

export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
})

export const resetPasswordSchema = yup.object().shape({
    newPassword: yup.string().min(8, "Password must be at least 8 characters long").required("Password is required"),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})