import React from 'react';

type DefaultProps = Record<string, never>;

export type OptionalChild<T = React.ReactNode> = { children?: T };
export type RequiredChild<T = React.ReactNode> = Required<OptionalChild<T>>;

export type Props<P = DefaultProps> = {
    [K in keyof P]: P[K];
};

// Typing with React.FC always opens components to take children, which is not always wanted.

/**
 * Component with no children.
 */
export type FC<P = DefaultProps> = (props: P) => React.ReactElement | null;

/**
 * Component with optional children.
 */
export type FCC<P = DefaultProps, C = React.ReactNode> = (
    props: Props<P> & OptionalChild<C>
) => React.ReactElement | null;

/**
 * Component with required children.
 */
export type FCR<P = DefaultProps, C = React.ReactNode> = (
    props: Props<P> & RequiredChild<C>
) => React.ReactElement | null;

export type User = {
    _id?: string
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    telephone: string;
    address: string;
    company: string;
    logo: any;
    primaryColor: string;
    secondaryColor: string;
    isActive: boolean;
    roles: Array<string>;
    isOnboardingComplete: boolean;
    stripeAccountId: string;
}

export type Order = {
    id?: string;

    name?: string;

    displayFulfillmentStatus?: string;

    displayFinancialStatus?: string;

    createdAt?: string;

    customer?: Customer;

    customerName?: string;

    customerEmail?: string;

    shippingAddress?: ShippingAddress;

    shippingAddressArea?: string;

    currentSubtotalPriceSet?: string;

    lineItems?: Array<LineItem>;

    tags: Array<string>;

    currentTotalWeight?: number;
}

type Customer = {
    email: string;
    verifiedEmail?: boolean;
    displayName?: string;
    phone?: string;
    state?: string;
}

type ShippingAddress = {
    address1: string;
    phone?: string;
    coordinatesValidated?: boolean;
    formatted?: Array<string>;
    formattedArea?: string;
}

export enum WEIGHT_UNIT {
    POUNDS = 'lbs',
    GRAMS = 'g',
    KILOGRAMS = 'kg',
    OUNCES = 'oz',
}

export type LineItem = {
    id?: string;
    title?: string
    quantity?: number
    sku?: string;
    originalUnitPriceSet?: any;
    originalTotalSet?: any;
    image?: any;
    vendor?: string;
    unfulfilledQuantity: number;
    variant?: {
        weight?: number;
        weightUnit?: 'POUNDS' | 'GRAMS' | 'KILOGRAMS' | 'OUNCES';
        id?: string
    }
}

export enum STATES {
    ACTIVE = 'active',
    DRAFT = 'draft',
    ARCHIVED = 'archived',
    TREATED = 'fulfilled',
    UNTREATED = 'unfulfilled',
    PAID = 'paid',
    AUTHORIZED = 'authorized',
    PENDING = 'pending',
    UNPAID = 'unpaid',
    PARTIALLY_TREATED = 'partially_fulfilled',
    PARTIALLY_REFUNDED = 'partially_refunded',
}