/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/styles/global.css"
import { createRoot } from 'react-dom/client';

 // Hydrate styles on production build
export function replaceHydrateFunction() {
    return (element, container, callback) => {
        const root = createRoot(container); // createRoot(container!) if you use TypeScript
        root.render(element);
    }
}
