import { STATES } from './types';

export const setCookie = (cookie: string, value: string): void => {
  if (typeof window !== 'undefined') {
    window.document.cookie = `${cookie}=${value}; path=/`;
  }
};

export const getCookie = (cookie: string): string | null => {
  if (typeof window !== 'undefined') {
    const cookies = window.document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const [key, value] = cookies[i].split('=');
      if (key.trim() === cookie) {
        return value;
      }
    }
  }
  return null;
};

interface StrapiField {
  name: string;
  label: string;
}

export const getStrapiFieldByName = (fieldName: string, strapiFields: Array<StrapiField>): StrapiField => {
  const result = strapiFields.find((field) => field.name === fieldName);

  if (result) {
    return result;
  }

  return { name: '', label: '' };
}

export const getTranslatedStatus = (status: string): string => {
  let value: string = status

  switch (status) {
    case STATES.PAID:
      value = "Payée"
      break
    case STATES.UNPAID:
      value = "Non Payée"
      break
    case STATES.AUTHORIZED:
      value = "Authorisée"
      break
    case STATES.ACTIVE:
      value = "Actif"
      break
    case STATES.ARCHIVED:
      value = "Archivée"
      break
    case STATES.DRAFT:
      value = "Brouillon"
      break
    case STATES.PENDING:
      value = "En attente"
      break
    case STATES.TREATED:
      value = "Traitée"
      break
    case STATES.UNTREATED:
      value = "Non Traitée"
      break
    case STATES.PARTIALLY_TREATED:
      value = "Partiellement Traitée"
      break
    case STATES.PARTIALLY_REFUNDED:
      value = "Partiellement Remboursée"
      break
  }

  return value
}