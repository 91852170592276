import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, Typography, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '/content/images/logo-gigasite.svg';

import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        padding: theme.spacing(3, 0),
        marginTop: 'auto',
        bottom: '0',
        position: 'relative',
    },
    content: {
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '0 !important',
        gap: '1rem'
    }
}));

const Footer: FC = () => {
    const styles = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return (
        <footer className={styles.footer}>
                <Container maxWidth="md" className={styles.content} sx={{flexDirection: isMobile ? 'column' : 'initial'}}>
                    <img src={Logo} alt='Giga site logo' width={150}/>
                    <Typography variant="body1" color="textSecondary" sx={{mx: 2}}>
                        © {new Date().getFullYear()}, {' '}
                        <Link href="https://giga-site.ca" color="inherit" sx={{textDecoration: 'none'}}>
                            GIGA-SITE
                        </Link>
                    </Typography>
                </Container>
            </footer>
        );
};

export default Footer;
