exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-products-add-tsx": () => import("./../../../src/pages/app/products/add.tsx" /* webpackChunkName: "component---src-pages-app-products-add-tsx" */),
  "component---src-pages-app-products-edit-[id]-tsx": () => import("./../../../src/pages/app/products/edit/[id].tsx" /* webpackChunkName: "component---src-pages-app-products-edit-[id]-tsx" */),
  "component---src-pages-app-products-index-tsx": () => import("./../../../src/pages/app/products/index.tsx" /* webpackChunkName: "component---src-pages-app-products-index-tsx" */),
  "component---src-pages-app-settings-tsx": () => import("./../../../src/pages/app/settings.tsx" /* webpackChunkName: "component---src-pages-app-settings-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgotPassword.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

