import axios, { AxiosError } from 'axios'
import { getCurrentUser, isBrowser, logout } from '../services/auth'

const axiosInstance = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 20000,
})

axiosInstance.interceptors.request.use(
    (req) => {

        if(req.url?.includes('users') || req.url?.includes('shopify')) {
            const { access_token } = getCurrentUser()
            req.headers['authorization'] = `Bearer ${access_token}`
        }

        return req
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        
        if(response.status === 401 || response.data.statusCode === 401  || response.data.message === '401 Unauthorized') {
            logout(() => {
                if (isBrowser()) {
                    location.href = '/login?status=expiredToken'
                }
            })
        }

        return response
    },
    (error) => {

        if(isNotAuthorized(error)) 
        {
            logout(() => {
                if(isBrowser()) {
                    location.href = '/login?status=expiredToken'
                }
            })
        } else if (hasHttpAccess(error)) {
            if(isBrowser()) {
                alert(error.response?.data?.message)
            }

            return
        }

        return Promise.reject(error)
    }
)

const hasHttpAccess = (error: AxiosError<any>): boolean => {
    return (error.response?.status === 403 || error.response?.data?.statusCode === 403 
    || error.response?.data?.error === 'Forbidden')
}

const isNotAuthorized = (error: AxiosError<any>): boolean => {
    return (!error?.config?.url?.includes('auth') && (error.response?.status === 401
            || error.response?.data?.statusCode === 401 
            || error.response?.data?.message === '401 Unauthorized'))
}

export default axiosInstance