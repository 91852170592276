import * as React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
    Container, Theme, IconButton, Divider,
    CssBaseline, Box, Toolbar, List
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import Header from './header';
import Footer from './footer';
import { FCR } from '../../util';
import { useStaticQuery, graphql } from 'gatsby';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ContentContext } from '../layout/layout'

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        marginTop: '2rem',
        backgroundColor: theme.palette.background.default
    },
    mainContainer: {
        height: 'calc(100% - 120px)',
        display: 'flex!important',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}));

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

type MetaLayoutProps = {
    switchTheme: () => void;
};

const MetaLayout: FCR<MetaLayoutProps> = (props) => {
    const { allStrapiNav } = useStaticQuery(
        graphql`
            query {
                allStrapiNav {
                    nodes {
                        localizations {
                            data {
                              attributes {
                                title
                                orders {
                                  title
                                  url
                                }
                                products {
                                  title
                                  url
                                }
                                settings {
                                  title
                                  url
                                }
                              }
                            }
                        }
                        title
                        orders {
                            title
                            url
                        }
                        products {
                            title
                            url
                        }
                        settings {
                            title
                            url
                        }
                    }
                }  
            }
        `
    );

    const { lang, setLanguage } = React.useContext(ContentContext)

    const menus = lang === 'fr' ? allStrapiNav.nodes[0] || {} : allStrapiNav.nodes[0].localizations.data[0].attributes || {};

    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const styles = useStyles();
    const location = useLocation()

    const { title } = useSiteMetadata();

    const handleNavigateTo = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        path: string,
    ) => {
        event.stopPropagation()
        navigate(path)
    };

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const changeLang = () => {
        setLanguage(lang === "fr" ? "en" : "fr")
    }

    React.useEffect(() => {

        if (isMobile && open) {
            toggleDrawer()
        }
    }, [isMobile, lang]);

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header siteTitle={title} open={open} switchTheme={props.switchTheme} toggleDrawer={toggleDrawer} lang={lang==="fr"?"en":"fr"} switchLanguage={changeLang} />
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    {menus?.title}
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <ListItemButton selected={location.pathname === menus?.orders?.url}
                        onClick={(event) => handleNavigateTo(event, menus?.orders?.url)}>
                        <ListItemIcon>
                            <InventoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={menus?.orders?.title} />
                    </ListItemButton>
                    <ListItemButton selected={location.pathname === menus?.products?.url}
                        onClick={(event) => handleNavigateTo(event, menus?.products?.url)}>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText primary={menus?.products?.title} />
                    </ListItemButton>
                    <Divider sx={{ my: 1 }} />
                    <ListItemButton selected={location.pathname === menus?.settings?.url}
                        onClick={(event) => handleNavigateTo(event, menus?.settings?.url)}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={menus?.settings?.title} />
                    </ListItemButton>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }} className={styles.mainContainer}>
                    <main className={styles.main}>{props.children}</main>
                    <Footer />
                </Container>
            </Box>
        </Box>
    );
};

export default MetaLayout;
